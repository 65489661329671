html,
body {
    background-color: #111215 !important;
}

#root {
    display: flex;
    width: 100%;
    min-height: 100vh;
}

main {
    width: 100%;
    margin: auto;
}

.aspect-ratio-1-1 {
    aspect-ratio: 1 / 1;
    object-fit: cover;
}
.flex-1 {
    flex: 1;
}

.card-body.dark,
.modal-dialog.dark > .modal-content {
    background-color: #14161b;
}
.unib-color {
    background-color: #1a1c25;
}
.text-muted:not(.mdb-sucks) {
    color: #acadaf !important;
}
.md-form {
    margin: 0;
}

body,
.sidebar,
main,
.card-title {
    color: #dcddde !important;
}
.card-text {
    color: #a2a6ad !important
}

.row {
    margin: 0;
}

.square {
    aspect-ratio: 1 / 1;
}

.unlink {
    color: unset;
    text-decoration: unset;
    transition: unset;
}

.container.fluid {
    padding: 2rem 3rem;
}

.cover {
    position: relative;
}

.cover .cover-img:after {
    background-image: linear-gradient(
180deg,transparent 0,rgba(0,0,0,.35) 70%,rgba(0,0,0,.7));
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.cover .details {
    position: absolute;
    bottom: 0;
}

.details h2, .details h3, .details h4, .details h5 {
    font-weight: 600;
    margin: 0;
}

@media (min-width: 576px) {
    .container.fluid {
        max-width: 900px;
    }
}

@media (min-width: 1200px) {
    main {
        margin: auto;
    }

    .container.fluid {
        max-width: 1140px;
    }

    @media (max-width: 1919px) {
        .row.summary .col-6:nth-child(5), .row.summary .col-6:nth-child(6) {
            display: none;
        }
    }
}


@media (min-width: 1920px) {
    .container.fluid {
        max-width: 1440px;
    }
}

@media (min-width: 2048px) {
    .container.fluid {
        max-width: 1700px;
    }
}

@media (min-width: 3200px) {
    .container.fluid {
        max-width: 2560px;
    }
}

@media (min-width: 1920px) {
    .col-xxl-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
}
