.show[x-placement="right"] {
    left: unset !important;
    right: -0.5rem;
    top: -1rem !important;
    z-index: 999;
}
i.fa {
    margin-right: 0.5rem;
}

.card-wrapper .card-rotating .front {
    position: relative;
}
.card-rotating {
    transition: none !important;
}
.card-rotating > .card,
.card-rotating > .card > .card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.card.card-cascade .view.view-cascade {
    border-radius: 0;
}
.card-wrapper .card-rotating .front,
.card-wrapper .card-rotating .back,
.card-wrapper .card-rotating .face {
    top: 0;
    background: transparent;
    transition: visibility, opacity 1s !important;
}
.card-rotating.flipped > .front {
    visibility: hidden;
    opacity: 0;
}
.card-rotating:not(.flipped) > .back {
    visibility: hidden;
    opacity: 0;
}

.user-card {
    background: url(https://abs-0.twimg.com/emoji/v2/svg/1f921.svg) center 33%/50% 50% no-repeat !important;
}
.user-info-card .gold {
    color: #e2b920 !important;
}
.user-info-card .silver {
    color: #b7b9bd !important;
}
.user-info-card .bronze {
    color: #9c6c3d !important;
}
.user-card,
.user-info-card {
    background: transparent;
        box-shadow: none;
}

.user-summary {
    text-align: right;
}
.username {
    flex: 1 0;
    text-align: left;
}
.username,
.username * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.full-name {
    font-size: 0.875rem;
}

ul.user-summary > li {
    display: flex;
}
.right i.fa-angle-double-right,
.user-info-card i.fa-angle-double-left {
    font-size: 1.375rem;
}

.rank {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0.375rem;
    padding: 0.25rem 0.625rem;
    font-size: 1rem;
    background: #6044b2 !important;
    z-index: 999;
}
.rank.gold {
    background: #e2b920 !important;
}
.rank.silver {
    background: #b7b9bd !important;
}
.rank.bronze {
    background: #9c6c3d !important;
}

.inner {
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    line-height: 1;
    text-align: center;
}
.inner span {
    font-size: 0.875rem;
}

.circle-progress-wrapper {
    padding: 0 2.5rem;
}
.lvl {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: absolute;
    top: -1rem;
    width: 2rem;
    height: 1.5rem;
    padding-right: 0.25rem;
    background: #6d53bd;
    border-radius: 0 99rem 99rem  0;
    box-shadow: 0.25rem 0 0.5rem rgba(0,0,0,0.125);
    z-index: 3;
}
.progress {
    position: absolute;
    top: -0.5rem;
    right: 0;
    width: calc(100% - 1.9375rem);
    height: 0.5rem;
    background: #798088;
    border-radius: 0;
    box-shadow: 0 0 0.375rem rgba(0,0,0,0.25);
    z-index: 1;
}
.fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    float: right;
    background: #7d65c4;
    border-radius: 0;
    z-index: 2;
}

.info-summary-outer-wrapper {
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
}
.info-summary {
    display: flex;
    flex-direction: row;
    font-size: 1.125rem;
}
.info-summary-outer-wrapper,
.info-summary-col {
    display: flex;
    flex-direction: column;
}
.info-summary-col > i.fa {
    display: flex;
    justify-content: center;
    align-items: center;
    /*  fallback for legacy browsers
        space-around unsupported
        NOTE: this has to match the line-height of the other column */
    height: 1.5rem;
}
